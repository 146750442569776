._1QK5bjmzC17Hb6XSD9r81s {
  position: fixed;
  top: 72px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
._3kKF23zz2oux3gIpfXAtOq {
  background-color: #ffffff;
  border: 1px solid #ea5f00;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px 36px;
  width: 600px;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.3s ease;
}
._3DCbKpE9pAgQUewQ_FFGP5 {
  transform: translateY(0);
  opacity: 1;
}
._2uOFEsyU--9n6OTPcEhNXs {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}
